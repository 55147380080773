import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    document.title = "Radiation Wars - Coming Soon!";
  }, []);

  return (
    <div class="container">
        <img src="/RadiationWarsLogo001.webp" width="600" alt="Radiation Wars Logo" />
        <h1>Radiation Wars</h1>
        <p>A tower defense game in a radation soaked landscape. Battle for new team members, and collect mutation points to level up your team.</p>
        <p class="coming-soon">Coming Soon - Stay Tuned!</p>
    </div>
  );
}

export default App;
